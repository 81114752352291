.mobile-min {
	width: 100%;
}

@media only screen and (max-width: 380px) {
	.mobile-min {
		width: min-content;
	}
}

ins.adsbygoogle[data-ad-status='unfilled'] {
	display: none !important;
}
