@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'reset';
@import 'preloader';
@import 'fullsize-image';
@import 'inputs';
@import 'animations';
@import 'alert';
@import 'recaptcha';
@import 'custom-scrollbar';
@import '../node_modules/simplebar-react/dist/simplebar.min.css';
@import 'tournamentView';
// index files for each feature
@import 'features';
// end index files for each feature

// safari disable zoom
body {
	touch-action: pan-y;
	* {
		-webkit-tap-highlight-color: transparent;
	}
}

html,
body {
	height: 100% !important;
	width: 100% !important;
	scroll-behavior: smooth;
}

#__next {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
