.tournament-register-button:hover {
	svg path {
		fill: white;
	}
}

.tournament-register-view-button {
	svg path {
		fill: white;
	}
}

.tournaments-listing-all-gamys {
	background: white;
}

@media only screen and (max-width: 639px) {
	.tournaments-listing-all-gamys {
		background: linear-gradient(rgba(230, 238, 243, 0.8) 0%, rgba(244, 247, 249, 1) 100%);
	}
}
