.stats-container {
	@apply flex justify-center flex-col items-center w-max;
}

.stats {
	@apply font-bold lg:text-4xl md:text-[30px] sm:text-[20px] text-[30px] xl:mb-5 text-center;
}

@keyframes rotateSelf {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotateOrbit {
	0% {
		transform: rotate(0deg) translateY(-30px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translateY(-30px) rotate(-360deg);
	}
}

@keyframes rotateUpDown {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes rotateUpDownReverse {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0);
	}
}

.floatingIcon {
	position: absolute;
	z-index: -1;
}

.floatingIcon-1 {
	@apply -top-6 lg:left-0 left-4 lg:w-9 lg:h-[29px] md:w-[19px] md:h-[18px] w-[27px] h-6;
	animation: rotateUpDown 6s ease-in-out infinite;
}

.floatingIcon-2 {
	@apply -top-6 lg:right-0 right-6 lg:w-[30px] lg:h-[31px] md:w-[18px] md:h-[18px] w-[22px] h-[23px];
	animation: rotateSelf 6s ease-in-out infinite;
}

.floatingIcon-3 {
	@apply top-0 lg:left-24 left-20 lg:w-[30px] lg:h-[31px] md:w-[22px] md:h-[22px] w-[22px] h-[23px];
	animation: rotateOrbit 6s ease-in-out infinite;
}

.floatingIcon-4 {
	@apply -top-5 lg:left-10 left-7 lg:w-[31px] lg:h-[34px] md:w-[23px] md:h-[25px] w-[23px] h-6;
	animation: rotateUpDownReverse 7s ease-in-out infinite;
}

@media only screen and (min-width: 1440px) {
	.stats-container {
		margin-top: 4.5rem;
	}

	.stats-container:not(:last-child) {
		margin-right: 2.625rem;
	}
}

@media only screen and (max-width: 1439px) and (min-width: 1280px) {
	.stats-container {
		margin-top: 5rem;
	}

	.stats-container {
		margin-right: 1.7rem;
	}

	.floatingIcon-4 {
		@apply -top-5 lg:left-10 left-7;
		animation: rotateUpDownReverse 7s ease-in-out infinite;
	}
}

@media only screen and (max-width: 1279px) and (min-width: 1024px) {
	.stats-container {
		margin-top: 3rem;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 640px) {
	.stats-container {
		margin-top: 2.5rem;
		width: auto;
	}
}

@media only screen and (max-width: 767px) and (min-width: 640px) {
	.stats-container {
		margin-top: 2.5rem;
	}
}

@media only screen and (max-width: 639px) {
	.stats-container {
		margin-top: 2.375rem;
	}

	.floatingIcon-1 {
		@apply -top-6 lg:left-0 left-4;
		animation: rotateUpDown 6s ease-in-out infinite;
	}

	.floatingIcon-2 {
		@apply -top-6 lg:right-0 right-6;
		animation: rotateSelf 6s ease-in-out infinite;
	}

	.floatingIcon-3 {
		@apply top-0 lg:left-24 left-20;
		animation: rotateOrbit 6s ease-in-out infinite;
	}

	.floatingIcon-4 {
		@apply -top-5 lg:left-10 left-7;
		animation: rotateUpDownReverse 7s ease-in-out infinite;
	}
}
