.championship-background-dark {
	background-blend-mode: multiply;
	background: no-repeat;
}

.championshipSection {
	width: max-content;
}

.endedTournaments-tournament-name {
	max-width: 95%;
}

@media only screen and (max-width: 1400px) {
	.endedTournaments-tournament-name {
		width: 90%;
	}
}

@media only screen and (max-width: 1279px) and (min-width: 1024px)  {
	.championshipSection {
		width: 903px;
	}
}

@media only screen and (max-width: 639px) {
	.championshipSection {
		width: 100%;
	}
}
