.content-dim {
	@apply opacity-50 transition-opacity duration-150;
}

.mobile-nav-wrap {
	@apply fixed lg:hidden pl-5 pt-20 w-full h-full flex flex-col bg-white z-[11];
}

.nav-items-container {
	@apply overflow-y-auto pb-7 pr-5;
}

.nav-wrap {
	@apply flex w-full pl-7 pr-6 justify-between items-center fixed top-0 bg-white duration-200 font-medium z-20 h-18 border-b-[1px] border-brightGray-200 transition-all;

	transition: background 0.3s ease, box-shadow 0.3s ease;
	box-shadow: inset 0 -1px 0 0 var(--accents-2);
	transform: translateZ(0);
	-webkit-backdrop-filter: saturate(180%) blur(5px);
	backdrop-filter: saturate(180%) blur(5px);
	background: hsla(0, 0%, 100%, 0.9);
}
