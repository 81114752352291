@layer components {
	.input-valid {
		@apply w-full bg-dark-100 text-dark-500 font-semibold text-sm rounded-lg transition-colors border border-dark-100 focus:outline-none focus:border-main-500;
	}
	.input-error {
		@apply w-full bg-dark-100 text-dark-500 font-semibold text-sm rounded-lg transition-colors border border-razzmatazz-500 focus:outline-none;
	}
	.input-checkbox-valid {
		@apply bg-white border-2 rounded border-gray-400 w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-main-500;
	}
	.input-checkbox-error {
		@apply bg-white border-2 rounded border-razzmatazz-500 w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2;
	}
}

input.checkbox:checked + svg {
	display: block;
}
