.fullsize-image {
	position: relative;
	display: block;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	& > img {
		position: absolute;
		top: 0;
		left: 0;
		height: 1px;
		width: 1px;
	}
}
