* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

@media (hover) {
	*::-webkit-scrollbar {
		display: none;
	}
}

@media (not(hover)) {
	*:not(.showBar)::-webkit-scrollbar {
		display: none;
	}
}
