.promo-section-container {
	a {
		color: rgba(0, 101, 250, 1);
	}

	a:hover {
		text-decoration: underline;
	}

	img {
		display: inline-block;
	}

	u {
		a {
			color: inherit !important;
		}
	}

	strong {
		a {
			color: inherit !important;
		}
	}

	ul {
		list-style: none;
		padding-left: 4rem;
		li {
			display: list-item;
		}
		li::before {
			content: counter(bullet-list, disc) '  ';
		}
	}

	ol {
		list-style: none;
		counter-reset: ordered-list-counter;

		li {
			display: list-item;
			counter-increment: ordered-list-counter;
		}
		li::before {
			content: counter(ordered-list-counter, decimal) '. ';
		}
	}
}

.slide {
	opacity: 0;
	transition: 1s ease;
}

.slide.active {
	opacity: 1;
	transition-duration: 1s;
	transform: scale(1.08);
}
