.gameplay-wrapper {
	@apply flex h-full w-full relative;
}
.gameplay-wrapper-lobby {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.7) 100%);
}
.gameplay-wrapper-play {
	background: linear-gradient(180deg, rgba(1, 1, 1, 1) 0%, rgba(85, 85, 85, 1) 100%);
}

@media only screen and (max-width: 1023px) {
	.gameplay-wrapper {
		@apply flex flex-col w-full relative;
	}
}

@keyframes pulse-animation-correct {
	0% {
		box-shadow: 0 0 0 0px rgba(83, 242, 43, 1);
	}
	100% {
		box-shadow: 0 0 10px 15px rgba(255, 255, 255, 0);
	}
}
@keyframes pulse-animation-wrong {
	0% {
		box-shadow: 0 0 0 0px rgba(235, 87, 87, 1);
	}
	100% {
		box-shadow: 0 0 10px 15px rgba(255, 255, 255, 0);
	}
}
@keyframes pulse-animation-no-answer {
	0% {
		box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
	}
	100% {
		box-shadow: 0 0 10px 15px rgba(255, 255, 255, 0);
	}
}
.pulse-correct {
	animation: pulse-animation-correct 1.1s infinite;
}
.pulse-wrong {
	animation: pulse-animation-wrong 1.1s infinite;
}
.pulse-no-answer {
	animation: pulse-animation-no-answer 1.1s infinite;
}

#desktopLobbyListId .simplebar-offset {
	@apply flex justify-center;
}

.statisticsBarGameplay {
	@apply flex flex-col justify-end items-center h-0 1xl:min-h-[13.75rem] lg:min-h-[7.5rem] sm:min-h-[9.375rem] min-h-[13.75rem];
}

@media only screen and (max-width: 389px) {
	.statisticsBarGameplay {
		@apply min-h-[110px];
	}
}

.gameplayPodiumIcon {
	@apply s:h-auto;
}

@media only screen and (max-width: 389px) {
	.gameplayPodiumIcon {
		@apply h-[6.25rem];
	}
}
