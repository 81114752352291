.body-is-fullscreen {
	overflow: hidden;
}

.childDiv {
	height: 100%;
}

.dot {
	width: 10px;
	height: 10px;
	border-radius: 9999px;
	margin: 0 4px;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-name: bounce;
}

@keyframes bounce {
	0%,
	100% {
		transform: translateY(-100%);
	}
	50% {
		transform: translateY(0);
	}
}

.dot:nth-child(2) {
	animation-delay: 200ms;
}

.dot:nth-child(3) {
	animation-delay: 400ms;
}

.headerSection {
	@apply font-medium text-dark-500;
}

@media only screen and (min-width: 1024px) {
	.headerSection {
		font-size: 45px;
	}
}

@media only screen and (max-width: 1023px) {
	.headerSection {
		font-size: 30px;
	}
}
