@layer components {

  @media only screen and (max-width: 639px) {
    .partnerBox {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      background: white;
      border-radius: 5px;
    }

    .partnerBox:not(:last-child) {
      margin-bottom: 30px;
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 1023px) and (min-width: 640px) {
    .partnerBox {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      background: white;
      border-radius: 5px;
    }

    .partnerBox:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media only screen and (min-width: 1024px) {
    .partnerBox:hover {
      cursor: pointer;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      background: white;
      border-radius: 5px;
    }

    .partnerBox:not(:last-child) {
      margin-right: 35px;
    }
  }

}

.partners-list-wrap {
  @apply flex flex-col justify-center w-full sm:mt-[10px];
}

.partners-list-title {
  @apply text-center font-normal text-2xsbase text-backgroundGray-400 sm:mx-0 mx-5;
}

.partners-list-items {
  @apply flex sm:flex-row flex-col justify-center sm:mt-[30px] mt-13 sm:mb-[130px] mb-30 items-center;
}

.partner-box-wrap {
  @apply lg:w-[200px] lg:h-[90px] sm:w-[170px] sm:h-[80px] w-[200px] h-[90px] flex justify-center partnerBox sm:mb-0;
}

.partner-box-content {
  @apply w-[150px] h-[45px] lg:mx-[25px] sm:mx-[21px] mx-[25px] flex self-center relative;
}