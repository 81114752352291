.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.95);
	}
	100% {
		transform: scale(1);
	}
}

.pulse {
	animation-name: pulse;
	animation-duration: 1s;
}
