.leaderboard-history-tournament-name {
	max-width: 100%;
}

@media only screen and (max-width: 1680px) and (min-width: 1600px) {
	.leaderboard-history-tournament-name {
		max-width: 67%;
	}
}

@media only screen and (max-width: 1599px) and (min-width: 1301px) {
	.leaderboard-history-tournament-name {
		max-width: 90%;
	}
}

@media only screen and (max-width: 1300px) and (min-width: 1280px) {
	.leaderboard-history-tournament-name {
		max-width: 67%;
	}
}
